/* GENERAL */

.content {
    flex: 1;
}

.numeric {
    text-align: right;
}

a.button, input.button {
    display: inline-block;
    color: #FFF;
    background-color: #7FC400;
    border: 1px solid #5DA200;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    line-height: 1;
    padding: 9px 13px;
    margin: 5px;
}

img.help {
    display: block;
    margin: 0 auto;
}

.page-not-found {
    margin-top: 15%;
    font-size:48px;
    text-align:center;
}

/* TABLES */

table.data-list {
    border-collapse: collapse;
    width: 100%;
}

table.data-list tr {
    border: 0;
}

table.data-list td, table.data-list th {
    vertical-align: top;
}

table.data-list td.checkbox, table.data-list th.checkbox {
    vertical-align: middle;
    width: 30px;
}

table.data-list th {
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
    font-family: Verdana, Geneva, sans-serif;
    font-weight: normal;
    color: #888;
    font-size: 10px;
    line-height: 14px;
}

table.data-list tr.highlighted:hover {
    background-color: rgba(0, 153, 255, .05);
}

table.data-list tr.not-selectable {
    cursor: auto;
}

table.selectable tr {
    cursor: pointer;
}

table.zebra tr {
    border-bottom: 1px dotted #e0e0e0;
}
table.zebra tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* AUTOCOMPLETE */

.suggestions {
    position: fixed;
    background-color: white;
    border: 1px solid #c5c5c5;
    margin-top: 25px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
}

.suggestions span {
    padding: 5px;
    display: block;
}

.suggestion-active {
    background-color: #ededed;
    cursor: pointer;
}

/* LOADING INDICATOR */

.loader {
    margin: 10px auto;
    display: block;
    text-align: center;
    width: 100%;
}

.loader .ellipsis1 {
    border-radius: 100%;
    display: inline-block;
    animation: ellipsis-anim 0.72s 0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-fill-mode: both;
}

.loader .ellipsis2 {
    border-radius: 100%;
    display: inline-block;
    animation: ellipsis-anim 0.72s 0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-fill-mode: both;
}

.loader .ellipsis3 {
    border-radius: 100%;
    display: inline-block;
    animation: ellipsis-anim 0.72s 0.36s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-fill-mode: both;
}

@keyframes ellipsis-anim {
    0% {transform: scale(1);opacity: 1}
    45% {transform: scale(0.1);opacity: 0.7}
    80% {transform: scale(1);opacity: 1}
}

/* */

.search-form {
    margin-left: 15px;
}
.search-btn {
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
}

.book_item {
    width: 20%;
    padding: 15px;
    display: inline-table;
}
.book-cover {
    height: 180px;
    background-color: #fafafa;
    color: #444;
    text-align: center;
    text-decoration: none;
}
.book-cover img {
    height: 180px;
}

.book div {
    display: inline-block;
    vertical-align: top;
}
tr.new-group td {
    border-top: 1px dotted #e0e0e0;
}
tr.known-phrase {
    color: #396;
}
tr.unknown-phrase {
    color: #EB464D;
}
.base-form {
    font-weight: bold;
}
tr.known-phrase a:link, tr.known-phrase a:visited, tr.known-phrase a:hover,
tr.unknown-phrase a:link, tr.unknown-phrase a:visited, tr.unknown-phrase a:hover {
    color: inherit;
}

.tooltip-data {
    position: fixed;
    border: #c5c5c5 solid 1px;
    border-radius: 3px;
    background-color: white;
    padding: 8px;
}

.tooltip-word {
    font-family: Verdana, Geneva, sans-serif;
    padding-right: 25px;
}

.tooltip-frequency {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 11px;
    color: #888;
}

.dict-lang {
    display: inline-block;
    width: 460px;
    margin-bottom: 30px;
}
.dict-lang img {
    display: block;
    width: 300px;
    margin: 0 auto;
}
.dict div {
    display: inline-block;
    vertical-align: top;
}
.lang-flag-small {
    width: 150px;
}
.phrase-editor {
    padding:20px;
    background-color: #fff;
    width:375px;
    height:203px;
}
.term_link {
    cursor: pointer;
    text-decoration: underline;
}

.training-no-phrases {
    margin-top: 50px;
}
.training-block {
    display: block;
    width: 70%;
    margin: 20px auto 0;
    border: 1px solid #f0e0d0;
    background-color: #fff6c6;
    border-radius: 5px;
    text-align: center;
    padding: 20px 20px 30px;
}
.training-block .translation-quest {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
}
.training-block .audio-play-btn {
    height: 32px;
    cursor: pointer;
    opacity: 0.5;
}
.training-block .answer-placeholder {
    height: 30px;
    margin-bottom: 20px;
}
.training-block .correct-answer {
    color: #6C9;
    font-size: 24px;
}
.training-block .answer-by-letter {
    height: 40px;
}
.training-block .letter {
    display: inline-block;
    height: 40px;
    width: auto;
    min-width: 40px;
    margin: 0 3px;
}
.training-block .letter input {
    width: 40px;
    font-size: 24px;
    text-align: center;
}
.training-block input.answer-ok {
    color: #6C9;
}
.training-block input.answer-nok {
    color: #FC575E;
}
.training-block .letter-set {
    font-size: 24px;
    font-family: Verdana, Geneva, sans-serif;
}
